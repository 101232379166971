import { Nav,Navbar,Container,NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import MyServices from './services';
import CarBooking from './booking';
import ContactUs from './contact';
import Home from './home';

function Header() {
    return (
      <div>
      
      <Navbar className="custom-navbar navbar navbar navbar-expand-md navbar-dark bg-dark" arial-label="Furni navigation bar" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Black Car Any Time</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
            <ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
            {/* <li className="nav-item active">  */}
            <li className="nav-item"> 
              <a className="nav-link" href="#"
              onClick={()=>{
                const root = ReactDOM.createRoot(document.getElementById('home'));
                root.render(
                  <React.StrictMode>
                    <Home></Home>
                  </React.StrictMode>
                );
    
            }
            }
            >Home</a>
            </li>
        {/* <li><a className="nav-link" href="shop.html">Member</a></li> */}
        <li><a className="nav-link" href="#" onClick={()=>{
            const root = ReactDOM.createRoot(document.getElementById('home'));
            root.render(
              <React.StrictMode>
                <CarBooking></CarBooking>
              </React.StrictMode>
            );

        }
        }
        
        >Booking</a></li>
        <li><a className="nav-link" href='#' onClick={()=>{
            const root = ReactDOM.createRoot(document.getElementById('home'));
            root.render(
              <React.StrictMode>
                <MyServices></MyServices>
              </React.StrictMode>
            );
            
        }}>Services</a></li>
        <li><a className="nav-link" href="blog.html">About us</a></li>
        <li><a className="nav-link" href="#" onClick={()=>{
            const root = ReactDOM.createRoot(document.getElementById('home'));
            root.render(
              <React.StrictMode>
                <ContactUs></ContactUs>
              </React.StrictMode>
            );

        }
        }
        >Contact us</a></li>
 
        </ul>
            <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
                  <li><a className="nav-link" href="#"><img src="images/user.svg" /></a></li>
                  <li><a className="nav-link" href="cart.html"><img src="images/cart.svg" /></a></li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


</div>
    );
  }
  
  export default Header;
  